// Core

@import 'core';

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@700&family=Poppins:wght@700&display=swap');

.MuiDialog-paper {
  .MuiDialogTitle-root {
    border-bottom: none !important;
  }

  .MuiDialogActions-root {
    border-top: none !important;
  }
}

.MuiMenu-paper {
  width: fit-content;
}

.kitchen-color {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
}
.kitchen-color-blue {
  background-color: rgba(13, 110, 253, 0.2);
  border-color: rgba(13, 110, 253, 1);
}
.kitchen-color-indigo {
  background-color: rgba(102, 16, 242, 0.2);
  border-color: rgba(102, 16, 242, 1);
}
.kitchen-color-purple {
  background-color: rgba(111, 66, 193, 0.2);
  border-color: rgba(111, 66, 193, 1);
}
.kitchen-color-pink {
  background-color: rgba(214, 51, 132, 0.2);
  border-color: rgba(214, 51, 132, 1);
}
.kitchen-color-red {
  background-color: rgba(220, 53, 69, 0.2);
  border-color: rgba(220, 53, 69, 1);
}
.kitchen-color-orange {
  background-color: rgba(253, 126, 20, 0.2);
  border-color: rgba(253, 126, 20, 1);
}
.kitchen-color-yellow {
  background-color: rgba(255, 193, 7, 0.2);
  border-color: rgba(255, 193, 7, 1);
}
.kitchen-color-green {
  background-color: rgba(25, 135, 84, 0.2);
  border-color: rgba(25, 135, 84, 1);
}
.kitchen-color-teal {
  background-color: rgba(32, 201, 151, 0.2);
  border-color: rgba(32, 201, 151, 1);
}
.kitchen-color-cyan {
  background-color: rgba(13, 202, 240, 0.2);
  border-color: rgba(13, 202, 240, 1);
}
.kitchen-color-emerald {
  background-color: rgba(23, 162, 184, 0.2);
  border-color: rgba(23, 162, 184, 1);
}
.kitchen-color-brown {
  background-color: rgba(184, 94, 22, 0.2);
  border-color: rgba(184, 94, 22, 1);
}
.kitchen-color-gray {
  background-color: rgba(108, 117, 125, 0.2);
  border-color: rgba(108, 117, 125, 1);
}
.kitchen-color-white {
  background-color: rgba(255, 255, 255, 1);
  border-color: rgba(0, 0, 0, 1);
}
